import DamAxiosClient from "./DamAxiosClient";
import {PreferenceTypes} from "../types/preference";
import {Location} from "../types/owner-group";
import UrlBuilder from "../utils/urlBuilder";
import {apiStatus} from "../utils/constants";

const BASE_API_URI = UrlBuilder.buildBaseUrl("");
const DPS_API_URI = UrlBuilder.buildDpsUrl("");

const damAxiosClient: DamAxiosClient = new DamAxiosClient()


export const addPartner = async ( authInfo: any, partner: string): Promise<any> => {

    const path : string = DPS_API_URI + `/partner`
    const params : string = ''
    let data: any =  JSON.stringify({
        name: partner,
        createdBy: authInfo.username,
        createDate: Date.now(),
    })

    return damAxiosClient.post( path, params, authInfo.token, data )
};

export const addPreferences = async (token: string, preferenceSetupId: any, selectLocation: any ) => {

    let preferenceTypes: PreferenceTypes = {};
    if (selectLocation.carfax4Life_selected) {
        preferenceTypes.c4l = {}
    }
    if (selectLocation.isUclDealer_selected) {
        preferenceTypes.ucl = {}
    }

    let locationToPost = [{
        compCode: selectLocation.compCode,
        preferenceSetupId: preferenceSetupId,
        preferenceBody: preferenceTypes
    }];

    const path : string = DPS_API_URI + `/preferences/addLocation`
    const params : string = ''
    let data: any = JSON.stringify({preferenceDetails: locationToPost})

    return damAxiosClient.put( path, params, token, data )
};

export const closeRequest = async (token: string, preferenceSetupId: number) : Promise<any> => {

    const path : string = DPS_API_URI + `/preferences/close/${preferenceSetupId}`
    const params : string = ''
    const data: any = '{}'

    return damAxiosClient.post( path, params, token, data )
};

export const createPreferences = async (token: string, ownerGroup: string, locations: Location[]) => {

    let locationsToPost = locations.map( location => {
        let preferenceTypes: PreferenceTypes = {};
        if (location.carfax4Life_selected) {
            preferenceTypes.c4l = {}
        }
        if (location.isUclDealer_selected) {
            preferenceTypes.ucl = {}
        }
        return {
            compCode: location.compCode,
            preferenceBody: preferenceTypes
        }
    })

    const path : string = DPS_API_URI + `/preferences/create/${ownerGroup}`
    const params : string = ''
    let data: any = JSON.stringify({preferenceDetails: locationsToPost})

    return damAxiosClient.post( path, params, token, data )
};

export const deleteLocation = async (token: string, preferenceSetupId: number, compCode: string) : Promise<any> => {
    const path : string = DPS_API_URI + `/preferences/id/${preferenceSetupId}/location/${compCode}`
    const params : string = ''

    return damAxiosClient.delete( path, params, token )
};

export const getAuthGroups = async (token: string): Promise<any> => {
    const path : string = BASE_API_URI + `/okta-user-authorizations`
    return damAxiosClient.get( path, "", token )
};

export const getPartners = async (token: string) : Promise<any> => {
    const path : string = DPS_API_URI + '/partner'
    const params :any = {source:'dam-frontend'}

    return damAxiosClient.get( path, params, token )
};

export const getPreferences = async (token: string, ownerGroup: string) : Promise<any> => {
    const path : string = DPS_API_URI + `/preferences/${ownerGroup}`
    const params : string = ''

    return damAxiosClient.get( path, params, token )
};

export const getDealershipByCompCode = async (token: string, compCode: string) : Promise<any> => {
    const path : string = BASE_API_URI + `/business-user/${compCode}`
    const params : string = ''

    return damAxiosClient.get( path, params, token )
};

export const getDealershipByName = async(token: string, dealershipName: string) : Promise<any> => {

    let formattedDealershipName = decodeURI(dealershipName).trim();
    let searchValue = `companyName=${formattedDealershipName}`;

    const path : string = BASE_API_URI + `/business-user/query/${searchValue}`
    const params : string = ''

    return damAxiosClient.get( path, params, token )
};

export const getDealershipByProfileSearch = async(token: string, profileSearchValue: string) : Promise<any> => {
    const path : string = DPS_API_URI + `/search`
    const params :any = {query:profileSearchValue, source:'dam'}

    return damAxiosClient.get( path, params, token )
};

export const getOwnerGroups = async (token: string, ownerGroup: string, locationOnly: boolean = true) : Promise<any> => {
    const path : string = BASE_API_URI + `/owner-group/${ownerGroup}`
    const params : any = {locationsOnly:locationOnly}

    return damAxiosClient.get( path, params, token )
};

export const reputationGetPartners = async (token: string) : Promise<any> => {
    const path : string = BASE_API_URI + `/reputation-partner/get-partners `
    const params : string = ''

    return damAxiosClient.get( path, params, token )
};

export const reputationGetAssociation = async (token: string, customerCompCode: any) : Promise<any> => {
    const path : string = BASE_API_URI + `/reputation-partner/association/${customerCompCode}`
    const params : string = ''

    return damAxiosClient.get( path, params, token )
};

export const reputationMakeOrRemoveAssociation = async (token: string, body: any) : Promise<any> => {
    const path : string = BASE_API_URI + `/reputation-partner/association/batch`
    const params : string = ''

    return damAxiosClient.post( path, params, token, body );
};

export const reputationGetHistory = async (token: string, customerCompCode: any) : Promise<any> => {
    const path : string = BASE_API_URI + `/reputation-partner/get-history/${customerCompCode}`
    const params : string = ''

    return damAxiosClient.get( path, params, token )
};

export const getOwnerLocations = async(token: string, parentSalesforceId: string) : Promise<any> => {
    if (!parentSalesforceId || parentSalesforceId.trim().length === 0) {
        return {status: apiStatus.notFound}
    }

    let searchValue = `parentSalesforceId=${parentSalesforceId}`;
    const path : string = BASE_API_URI + `/business-user/query/${searchValue}`
    const params : string = ''

    return damAxiosClient.get( path, params, token )
};

export const getTopOwnerGroup = async(token: string, rootSalesforceId: string) : Promise<any> => {
    if (!rootSalesforceId || rootSalesforceId.trim().length === 0) {
        return {status: apiStatus.notFound}
    }
    let searchValue = `rootSalesforceId=${rootSalesforceId}, topLevelOwnergroup=true`;
    const path : string = BASE_API_URI + `/business-user/query/${searchValue}`
    const params : string = ''
    return damAxiosClient.get( path, params, token )
};

export const getChildrenByTopOwnerGroup = async(token: string, rootSalesforceId: string) : Promise<any> => {
    if (!rootSalesforceId || rootSalesforceId.trim().length === 0) {
        return {status: apiStatus.notFound}
    }
    let searchValue = `rootSalesforceId=${rootSalesforceId}`;
    const path : string = BASE_API_URI + `/business-user/query/${searchValue}`
    const params : string = ''
    return damAxiosClient.get( path, params, token )
};

export const submitBatchTask = async (token: string, data: any): Promise<any> => {
    const path : string = BASE_API_URI + `/batch/batchTaskExecutor`
    const params : string = ''

    return damAxiosClient.post( path, params, token, data )
};

export const getS3PresignedUrl = async (token: string, taskName: string): Promise<any> => {
    const path : string = BASE_API_URI + `/batch/retrievePresignedUrl?taskName=${taskName}`
    const params : string = ''

    return damAxiosClient.get( path, params, token )
};

export const uploadFileToS3 = async(presignedUrl: string, file: any): Promise<any> => {
    return damAxiosClient.putToS3( presignedUrl, file )
};

export const regenerateTopRatedDealers = async ( token: string, compCode: string): Promise<any> => {

    const path : string = BASE_API_URI + `/top-rated-image/regenerate-top-rated`
    const params : string = ''
    let data: any = {
        compCode: compCode
    }

    return damAxiosClient.post( path, params, token, data )
};