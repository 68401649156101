import * as React from "react";
import {RefObject} from "react"
import CommonUtils from "../../../utils/commonUtils";
import {DotsVertical, Locations, Plus} from "@carfax-stencils/icon";
import Store from "../../../stores";
import {Menu, MenuButton, MenuList, MenuListItem} from "@carfax-stencils/menu";
import {NoResults} from "../../../shared-components/noResults/NoResults";
import {observer} from "mobx-react";
import {ProcessingApiSpinner} from "../../../shared-components/processingApiSpinner/ProcessingApiSpinner";
import {Table, TBody, TD, TH, THead, TR} from "@carfax-stencils/table";
import {User} from "../../../types/acctMgmt/user";
import "../../../utils/newRelic/newrelic";
import "../../../styles/main.scss";
import "./DealerAcctMgmtDetailsTab.scss";
import {Tooltip} from "@carfax-stencils/tooltip";
import ModifyUserModal from "./ModifyUserModal";
import Button from "@carfax-stencils/button";
import ConfirmModal, {ConfirmProps} from "../../../shared-components/confirm/ConfirmModal";
import InviteUserModal from "./InviteUserModal";
import MoveUserModal from "./MoveUserModal";
import DevCfoRemoteLoginModal from "./DevCfoRemoteLoginModal";
import {DAM_Admin, DAM_Super_Admin} from "../../../types/groups";
import {Environment, getEnvironment} from "../../../utils/environment";


// Stores
const rootStore = Store.root.root;
const acctMgmtStore = rootStore.acctMgmtStore;
const businessUserStore = rootStore.businessUserStore;
const commonStore = rootStore.commonStore;
const userAuditHistoryStore = rootStore.userAuditHistoryStore;
const authStore = rootStore.authStore

interface DealerAcctMgmtTabProps {
    compCode:any
}


export default observer (
    class DealerAcctMgmtDetailsTab extends React.Component<DealerAcctMgmtTabProps> {

        state = {
            ready: false
        };

        private readonly moveUsersButtonTooltipRef: RefObject<HTMLButtonElement> = React.createRef();

        async componentDidMount() {
            this.setState(
                {ready:true}
            )
            if(businessUserStore.businessUser?.compCode) {
                await this.loadData()
            }
        };

        async componentDidUpdate() {
            if(businessUserStore.businessUser?.compCode !== acctMgmtStore.compCode) {
                await this.loadData()
            }
        };

        initializePage = async () => {
            acctMgmtStore.initializeStore()
        };

        private async loadData() {
            commonStore.processingApiCall(true)

            await Promise.all([
                acctMgmtStore.getUsersByCompCode()
            ]);

            commonStore.processingApiCall(false)
        };

        determineStatusColor = (status: string, suspended: boolean) : string => {

            let classNameStatus = ""

            if(suspended) {
                classNameStatus = "user-status-suspended"
            } else {
                if(status === "Active") {
                    classNameStatus = "user-status-active"
                }

                if(status === "Inactive") {
                    classNameStatus = "user-status-inactive"
                }

                if(status === "Invited") {
                    classNameStatus = "user-status-invited"
                }

                if(status === "Reinvited") {
                    classNameStatus = "user-status-reinvited"
                }
            }

            return classNameStatus
        }

        determineStatus = (status: string, suspended: boolean) : string => {
            let userStatus = "";

            if(suspended) {
                userStatus = "Suspended"
            } else {
                userStatus = status
            }

            return userStatus;
        }

        render() {
            if (!this.state.ready) {
                return (
                    <NoResults/>
                )
            }
            return (
                <div id="dealer-acct-mgmt-tab-div" className="left-padding dealer-acct-mgmt-tab-div">
                    {commonStore.isProcessingApi && (
                        <ProcessingApiSpinner/>
                    )}
                    <div className="dealer-acct-mgmt-heading-section">
                        <div id="dealer-acct-mgmt-active-users-count-div" className="active-users-count-div">
                            <p id="dealer-acct-mgmt-active-users-count" className="active-users-count">
                                {acctMgmtStore.userTotals?.totalActive}
                            </p>
                            <p id="dealer-acct-mgmt-active-users-label" className="active-users-label">
                                Active Users
                            </p>
                        </div>
                        <div id="dealer-acct-mgmt-buttons-div" className="dealer-acct-mgmt-button-section">
                            {!acctMgmtStore.moveUserStore.hasUsersSelected() ?
                                <div className="dealer-acct-mgmt-button">
                                    <Button
                                        id="invite-user-button"
                                        theme="blue"
                                        size="large"
                                        children={
                                            <div>
                                                <Plus size={24} className="invite-user-button-plus"/> Invite a User
                                            </div>}
                                        onClick={() => acctMgmtStore.inviteUserStore.handleInviteModelOpen()}
                                    />
                                </div>
                            :
                                <div id="move-users-div" className="dealer-acct-mgmt-button">
                                    <Button
                                        id="move-users-button"
                                        theme="blue-outline"
                                        ref={this.moveUsersButtonTooltipRef}
                                        size="large"
                                        disabled={!acctMgmtStore.moveUserStore.hasSelectedUsersEligibleToMove()}
                                        children={ acctMgmtStore.moveUserStore.selectedUsers.length >= 2 ?
                                            "Move Users"
                                            :
                                            "Move User"
                                        }
                                        onClick={() => acctMgmtStore.moveUserStore.openMoveUserModal()}
                                    />
                                    <Tooltip
                                        anchorRef={this.moveUsersButtonTooltipRef}
                                        theme="light"
                                        position="left">
                                        Users in Invited or Reinvited status cannot be moved.
                                    </Tooltip>
                                </div>
                            }
                            {(authStore.groups?.includes(DAM_Super_Admin) || authStore.groups?.includes(DAM_Admin)) &&
                            <>
                                <div id="suspend-all-div"  className="dealer-acct-mgmt-button">
                                    <Button
                                        id="suspend-all-button"
                                        theme="red-outline"
                                        size="large"
                                        children="Suspend All"
                                        disabled={!acctMgmtStore.enableSuspendAllButton()}
                                        onClick={() => acctMgmtStore.handleConfirmSuspendAllModelOpen()}
                                    />
                                </div>
                                <div id="unsuspend-all-div" className="dealer-acct-mgmt-button">
                                    <Button
                                        id="unsuspend-all-button"
                                        theme="blue-outline"
                                        size="large"
                                        children="Unsuspend All"
                                        disabled={!acctMgmtStore.enableUnsuspendAllButton()}
                                        onClick={() => acctMgmtStore.unsuspendAllUsers()}
                                    />
                                </div>
                            </>
                            }
                        </div>
                    </div>

                    <Table aria-describedby="acct-mgmt-users-table">
                        <THead  className="acct-mgmt-users-table__heading" 
                            onSelectAll={acctMgmtStore.moveUserStore.handleUserSelectAll}
                            selectedAll={acctMgmtStore.moveUserStore.isAllSelected()}>
                            <TH className="acct-mgmt-users-table__heading__TH" width={240}>Name</TH>
                            <TH className="acct-mgmt-users-table__heading__TH" width={80}>Role</TH>
                            <TH className="acct-mgmt-users-table__heading__TH" width={111}>Status</TH>
                            <TH className="acct-mgmt-users-table__heading__TH" width={110}>Last Login Attempt</TH>
                            <TH className="acct-mgmt-users-table__heading__TH" width={130}></TH>
                            <TH className="acct-mgmt-users-table__heading__TH" width={1}></TH>
                            <TH className="acct-mgmt-users-table__heading__TH" width={10}></TH>
                        </THead>
                        <TBody>
                            {acctMgmtStore.userRecords.map((userRecord: User, index: number) => (
                                <TR className="acct-mgmt-users-table__row" key={index}
                                    onSelect={() => acctMgmtStore.moveUserStore.handleUserSelect(userRecord)}
                                    selected={userRecord.selected}>
                                    <TD className="acct-mgmt-users-table__column">
                                        <div id="dealer-acct-mgmt-user-name-div" className="user-name-div">
                                            {userRecord.firstName + " " + userRecord.lastName}
                                            {userRecord.accessLocations.length > 0 &&
                                                <IconComponent/>
                                            }
                                        </div>
                                        <div id="dealer-acct-mgmt-user-email-div" className="user-email-div">
                                            {userRecord.email}
                                        </div>
                                        <div id="dealer-acct-mgmt-user-phone-div">
                                            {CommonUtils.formatPhoneNumber(userRecord.phone)}
                                        </div>
                                    </TD>
                                    <TD className="acct-mgmt-users-table__column">{userRecord.role}</TD>
                                    <TD className="acct-mgmt-users-table__column">{
                                        <div className="user-status-cell">
                                            <div className={this.determineStatusColor(userRecord.status, userRecord.suspended)}></div>
                                            <div className="user-status-text">{this.determineStatus(userRecord.status, userRecord.suspended)}</div>
                                        </div>}
                                    </TD>
                                    <TD className="acct-mgmt-users-table__column">{CommonUtils.convertEpochDateToMMDDYYYY(userRecord.lastLoginDate)}</TD>
                                    <TD className="acct-mgmt-users-table__column" >
                                        <div id="acct-mgmt-users-table-cfo-login-link" className="cfo-login-link">
                                            <CFOLoginComponent user={userRecord}/>
                                        </div>
                                    </TD>
                                    <TD className="acct-mgmt-users-table__column">
                                        <div id="acct-mgmt-users-table-user-elipses-menu">
                                            <EllipsesUserMenuComponent user={userRecord} compCodeSuspended={businessUserStore.businessUser?.turnedOff}/>
                                        </div>
                                    </TD>
                                    <TD className="acct-mgmt-users-table__column"></TD>
                                </TR>
                            ))}
                        </TBody>
                    </Table>
                    {acctMgmtStore.modifyUserStore.isUserModalVisible && <ModifyUserModal /> }
                    {acctMgmtStore.inviteUserStore.isInviteModalVisible && <InviteUserModal /> }
                    {acctMgmtStore.moveUserStore.isMoveModalVisible && <MoveUserModal /> }
                    {acctMgmtStore.isDevCfoRemoteLoginModalVisible && <DevCfoRemoteLoginModal />}
                    <ConfirmSuspendAllModal/>
                    <ConfirmSuspendUserModal/>
                    <ConfirmDeactivateDeleteUserModal/>
                </div>
            );
        };
    }
);


const IconComponent = (() => {
    const iconRef =  React.useRef(null)
    return (
        <div id="dealer-acct-mgmt-multi-locations-icon-main-div" className="icon-main-div">
            <Tooltip anchorRef={iconRef} theme="dark" position="right">This user is associated to multiple locations</Tooltip>
            <div id="dealer-acct-mgmt-multi-locations-icon-sub-div" ref={iconRef}><Locations/></div>
        </div>
    )
});

const EllipsesUserMenuComponent = observer( (props: {user: User, compCodeSuspended: any}) => {
    return (
       <div id="dealer-acct-mgmt-ellipses-menu-main-div">
           <Menu id="dealer-acct-mgmt-ellipses-menu" className="dealer-acct-mgmt-ellipses-menu">
               <MenuButton id="dealer-acct-mgmt-ellipses-menu-button" disabled={acctMgmtStore.moveUserStore.isMultipleSelected(props.user.userId)} theme="blue-flat" size="small">
                   <DotsVertical />
               </MenuButton>
               <MenuList id="dealer-acct-mgmt-ellipses-menu-list" className="dealer-acct-mgmt-ellipses-menu-list" width={250} position="left">
                   { (acctMgmtStore.isUserInvitedOrReinvited(props.user) && !props.compCodeSuspended && !props.user.suspended)
                       && <MenuListItem id="dealer-acct-mgmt-ellipses-menu-item-reinvite" className="dealer-acct-mgmt-ellipses-menu-list-item" onClick={() => acctMgmtStore.reinviteUserStore.resendInvite(props.user, businessUserStore.businessUser!)}>Re-invite User</MenuListItem>
                   }
                    <MenuListItem id="dealer-acct-mgmt-ellipses-menu-item-modify" className="dealer-acct-mgmt-ellipses-menu-list-item" onClick={() => acctMgmtStore.modifyUserStore.openModifyUserModal(props.user)}>Modify User</MenuListItem>
                   {( (!acctMgmtStore.isUserInvitedOrReinvited(props.user)))
                       ? <MenuListItem id="dealer-acct-mgmt-ellipses-menu-item-move" className="dealer-acct-mgmt-ellipses-menu-list-item" onClick={() => acctMgmtStore.moveUserStore.openMoveUserModal(props.user)}>Move User</MenuListItem>
                       : null
                   }
                   {/*<MenuListItem id="dealer-acct-mgmt-ellipses-menu-item-login" className="dealer-acct-mgmt-ellipses-menu-list-item" onClick={() => alert("Login to Service Console")}>Login to Service Console</MenuListItem>*/}
                   <MenuListItem id="dealer-acct-mgmt-ellipses-menu-item-history" className="dealer-acct-mgmt-ellipses-menu-list-item" onClick={() => userAuditHistoryStore.displayUserAuditHistoryModal(props.user)}>Account History</MenuListItem>
                   <MenuListItem id="dealer-acct-mgmt-ellipses-menu-item-horizontal-line" className="horizontal-line"></MenuListItem>
                   {props.user.status === "Inactive"
                       ? (<MenuListItem id="dealer-acct-mgmt-ellipses-menu-item-deactivate" className="dealer-acct-mgmt-ellipses-menu-list-item" onClick={() => acctMgmtStore.reactivateUser(props.user)}>Reactivate User</MenuListItem>)
                       : acctMgmtStore.isUserInvitedOrReinvited(props.user)
                            ? (<MenuListItem id="dealer-acct-mgmt-ellipses-menu-item-delete" className="dealer-acct-mgmt-ellipses-menu-list-item__red" onClick={() => acctMgmtStore.handleDeactivateDeleteUserModalOpen(props.user)}>Delete User</MenuListItem>)
                            : (<MenuListItem id="dealer-acct-mgmt-ellipses-menu-item-deactivate" className="dealer-acct-mgmt-ellipses-menu-list-item__red" onClick={() => acctMgmtStore.handleDeactivateDeleteUserModalOpen(props.user)}>Deactivate User</MenuListItem>)
                   }
                   { (authStore.groups?.includes(DAM_Super_Admin) || authStore.groups?.includes(DAM_Admin)) &&
                        <>
                        {props.user.suspended
                            ? (<MenuListItem id="dealer-acct-mgmt-ellipses-menu-item-unsuspend" className="dealer-acct-mgmt-ellipses-menu-list-item" onClick={() => acctMgmtStore.unSuspendUser(props.user)}>Unsuspend User</MenuListItem>)
                            : (<MenuListItem id="dealer-acct-mgmt-ellipses-menu-item-suspend" className="dealer-acct-mgmt-ellipses-menu-list-item__red" onClick={() => acctMgmtStore.handleConfirmSuspendUserModelOpen(props.user)}>Suspend User</MenuListItem>)
                        }
                        </>
                   }
                   { (getEnvironment() !== Environment.PROD) &&
                       <>
                            <MenuListItem id="dealer-acct-mgmt-ellipses-menu-item-horizontal-line" className="horizontal-line"></MenuListItem>
                            <MenuListItem id="dealer-acct-mgmt-ellipses-menu-item-dev-cfo-remote-login" className="dealer-acct-mgmt-ellipses-menu-list-item" onClick={() => acctMgmtStore.displayDevCfoRemoteLoginModal(props.user)}>Developer CFO Login</MenuListItem>
                       </>
                   }
               </MenuList>
           </Menu>
       </div>
   )
});

const CFOLoginComponent = observer( (props: {user: User}) => {
    return (
        <div>
            <Button
                id="CFO Remote Login button"
                theme="white"
                size="small"
                disabled={props.user.status !== 'Active' || props.user.suspended}
                onClick={() => acctMgmtStore.cfoLoginUasUser(props.user)}
            >
                CFO Login
            </Button>
        </div>
    )
});

const ConfirmSuspendAllModal = observer( () => {
    const confirmProps: ConfirmProps = {
        confirmButtonMessage: "Suspend All Users",
        headerTitle: "Are You Sure?",
        isVisible : acctMgmtStore.isConfirmSuspendAllModalVisible,
        onCloseMethod : acctMgmtStore.handleConfirmSuspendAllClose,
        theme: "red",
        confirmModalMessageJsx: (
            <div>
                You are about to suspend all of the users at this comp code.<br/><br/>
                If you continue, these users will no longer have access to CARFAX tools through <strong>{businessUserStore.businessUser?.companyName}</strong>. Suspended users cannot be reactivated by the dealership ADMIN.<br/><br/>
                Are you sure you want to continue?
            </div>)
    }
    return acctMgmtStore.isConfirmSuspendAllModalVisible ? (
        <ConfirmModal { ...confirmProps} />
    ) : null;
});

const ConfirmSuspendUserModal = observer( () => {
    const confirmProps: ConfirmProps = {
        confirmButtonMessage: "Suspend User",
        headerTitle: "Are You Sure?",
        isVisible : acctMgmtStore.isConfirmSuspendUserModalVisible,
        onCloseMethod : acctMgmtStore.handleConfirmSuspendUserClose,
        theme: "red",
        confirmModalMessageJsx: (
            <div>
                You are about to suspend user <strong>{acctMgmtStore.editingUser?.firstName + " " + acctMgmtStore.editingUser?.lastName}</strong>.<br/><br/>
                If you continue, this user will no longer have access to CARFAX tools through <strong>{businessUserStore.businessUser?.companyName}</strong>. Suspended users cannot be reactivated by the dealership ADMIN.<br/><br/>
            </div>)
    }
    return acctMgmtStore.isConfirmSuspendUserModalVisible ? (
        <ConfirmModal { ...confirmProps} />
    ) : null;
});

const ConfirmDeactivateDeleteUserModal = observer( () => {
    const deactivateConfirmMessageDiv = <>
        <div>
            You are about to
            deactivate <strong>{acctMgmtStore.editingUser?.firstName + " " + acctMgmtStore.editingUser?.lastName}</strong>.<br/><br/>
            If you continue, this user will no longer have access to CARFAX tools
            through <strong>{businessUserStore.businessUser?.companyName}</strong>. Deactivated users can be reactivated
            by the dealership ADMIN at any time.<br/><br/>
        </div>
    </>;
    const deleteConfirmMessageDiv = <>
        <div>
            You are about to
            delete <strong>{acctMgmtStore.editingUser?.firstName + " " + acctMgmtStore.editingUser?.lastName}</strong>.<br/><br/>
            Deleted users can be invited again at any time.<br/><br/>
        </div>
    </>;
    const confirmProps: ConfirmProps = {
        confirmButtonMessage: acctMgmtStore.isUserInvitedOrReinvited(acctMgmtStore.editingUser!) ? "Delete User" : "Deactivate User",
        headerTitle: "Are You Sure?",
        isVisible : acctMgmtStore.isConfirmDeactivateDeleteModalVisible,
        onCloseMethod : acctMgmtStore.handleDeactivateDeleteUserModalClose,
        theme: "red",
        confirmModalMessageJsx: acctMgmtStore.isUserInvitedOrReinvited(acctMgmtStore.editingUser!) ? deleteConfirmMessageDiv : deactivateConfirmMessageDiv
    }
    return acctMgmtStore.isConfirmDeactivateDeleteModalVisible ? (
        <ConfirmModal { ...confirmProps} />
    ) : null;
});