import * as React from "react";
import Breadcrumbs from "@carfax-stencils/breadcrumbs";
import Header from "../../shared-components/layout/header/Header";
import Page from "../../shared-components/layout/page/Page";
import Store from "../../stores";
import "../../styles/main.scss";
import {NoResults} from "../../shared-components/noResults/NoResults";
import {observer} from "mobx-react";
import {Tabs, TabList, Tab, TabPanel} from '@carfax-stencils/tabs';
import {withOktaAuth} from "@okta/okta-react";

import {
    Notification,
    NotificationCloseButton,
    NotificationIcon,
    NotificationMessage
} from "@carfax-stencils/notification";

import "./DealerAcctMgmt.scss";
import "../../styles/main.scss";
import "../../shared-components/noResults/noResults.scss";
import "../../utils/newRelic/newrelic";
import {acctMgmtTabs} from "../../types/profile/enums/acctMgmtTabs";
import DealerAcctMgmtTab from "./dealerAcctMgmtDetails/DealerAcctMgmtDetailsTab";
import SearchComponent from "../../shared-components/search/SearchComponent";
import LegacyAcctMgmtTab from "./legacyAcctMgmt/LegacyAcctMgmtTab";
import UserAuditHistoryModal, {UserAuditHistoryProps} from "./dealerAcctMgmtDetails/userAuditHistory/UserAuditHistoryModal";


// Props
type AcctMgmtProps = { oktaAuth: any; authState: any; match:any; emptyView?: JSX.Element; }

// Stores
const rootStore = Store.root.root;
const acctMgmtStore = rootStore.acctMgmtStore;
const businessUserStore = Store.search.businessUser;
const commonStore = Store.common.common;
const searchStore = rootStore.searchStore;
const userAuditHistoryStore = rootStore.userAuditHistoryStore;


export default withOktaAuth(

    class DealerAcctMgmtPage extends React.Component<AcctMgmtProps> {

        async componentDidMount() {
            await this.initializePage();
            const compCode = this.props.match.params.compCode
            if(compCode?.length > 0) {
                Store.search.search.handleCompCodeSearch(compCode);
            }
            this.setState({ready:true});
        };

        initializePage = async () => {
            commonStore.initializeStore();
            acctMgmtStore.initializeStore();
            searchStore.initializeStore()
        };

        protected switchTab = (selectedTab:number) => {
            acctMgmtStore.setCurrentTab(selectedTab)
            commonStore.clearPageMsg()
            commonStore.clearFeatureMsg()
        };

        UserAuditHistoryModalComponent = observer( () => {
            const userAuditHistoryProps: UserAuditHistoryProps = {
                isVisible: userAuditHistoryStore.isUserAuditHistoryModalVisible,
                store: userAuditHistoryStore
            }
            return userAuditHistoryStore.isUserAuditHistoryModalVisible ? (
                <UserAuditHistoryModal { ...userAuditHistoryProps } />
            ) : null;
        })

        render() {
            return (
                <div id="dealer-acct-mgmt-render-div">
                        <Page id="dealer-acct-mgmt-page" title="Dealer Account Management | Account Management">
                            <div id="dealer-acct-mgmt-page-div">
                                <Header id="dealer-acct-mgmt-page-header"/>
                                <this.PageContent/>
                            </div>
                        </Page>
                </div>
            )
        }

        PageContent = observer(() => {

            const hasData = businessUserStore.businessUser;

            return (
                <div id="dealer-acct-mgmt-outer-div">
                    <div id="dealer-acct-mgmt-breadcrumbs-div" className="breadcrumb-trail">
                        <Breadcrumbs
                            id="dealer-acct-mgmt-breadcrumbs"
                            crumbs={[
                                { value: "Home", href: "#" },
                                { value: "Account Management" },
                            ]}
                        />
                    </div>
                    <div id="dealer-acct-mgmt-page-heading-div" className="page__heading">
                        Dealer Account Management
                    </div>

                    {/*  COMMON PAGE-LEVEL NOTIFICATIONS  */}
                    {commonStore.pageMsg && (
                        <div id="dealer-acct-mgmt-page-notification-div" className="page-notification-div">
                            <Notification
                                id="dealer-acct-mgmt-page-notification"
                                role={"alert"}
                                scrollIntoView={true}
                                theme={commonStore.pageMsgTheme}>
                                <NotificationIcon/>
                                <NotificationMessage>
                                    {commonStore.pageMsg}
                                </NotificationMessage>
                                <NotificationCloseButton
                                    onClick={() => {commonStore.clearPageMsg()}}
                                />
                            </Notification>
                        </div>
                    )}

                    <div id="dealer-acct-mgmt-layout-search-outer-div" className="acct-mgmt-layout-wrapper">
                        <div id="dealer-acct-mgmt-layout-search-wrapper-div" className="acct-mgmt-layout-search-wrapper-div">
                            <SearchComponent />
                        </div>
                        {hasData && this.renderData()}
                        {!hasData && this.renderNoData()}
                    </div>
                </div>
            );
        });

        renderNoData() {
            return (
                <div id="dealer-acct-mgmt-layout-noData-outer-div" className="page__no-data">
                    <NoResults subtitle="Please use the search box on the left to view a specific dealership."/>
                </div>
            )
        }

        renderData() {
            return (
                <div id="dealer-acct-mgmt-layout-withData-outer-div" className="page__wrapper">

                    {/*  FEATURE-CONTENT NOTIFICATIONS  */}
                    {commonStore.featureMsg &&
                        <div id="dealer-acct-mgmt-layout-body-feature-message-div" className="feature-notification-layout">
                            <Notification
                                id="dealer-acct-mgmt-feature-notification"
                                role={"alert"}
                                scrollIntoView={true}
                                theme={commonStore.featureMsgTheme}>
                                <NotificationIcon/>
                                <NotificationMessage>
                                    {commonStore.featureMsg}
                                </NotificationMessage>
                                <NotificationCloseButton
                                    onClick={() => {commonStore.clearFeatureMsg()}}
                                />
                            </Notification>
                        </div>
                    }

                    <div id="dealer-acct-mgmt-main-layout" className="page__data  acct-mgmt-page-with-data">
                        <Tabs
                            id="dealer-acct-mgmt-main-layout-tabs"
                            selected={acctMgmtStore.currentTab}
                            onSelect={(index) => this.switchTab(index)}
                            theme={"bar"}
                        >
                            <TabList className="profile-mgmt-tablist">
                                <Tab>{acctMgmtTabs[0]}</Tab>
                                <Tab>{acctMgmtTabs[1]}</Tab>
                            </TabList>
                            <TabPanel>{acctMgmtStore.currentTab === 0 && <DealerAcctMgmtTab compCode={businessUserStore.businessUser?.compCode}/>}</TabPanel>
                            <TabPanel>{acctMgmtStore.currentTab === 1 && <LegacyAcctMgmtTab compCode={businessUserStore.businessUser?.compCode}/>}</TabPanel>
                        </Tabs>
                    </div>
                    <this.UserAuditHistoryModalComponent/>
                </div>
            )
        }
    }
);


